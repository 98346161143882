$white-color: #ffffff;
$lighter-color: #f9f9f9;
$light-color: #e3e3e3;
$medium-color: #7e7e7e;
$dark-color: #4c4c4c;
$darker-color: #242424;
$black-color: #000000;
$red-color: #e30613;
$orange-color: #e36f06;
$yellow-color: #ffdd00;
$green-color: #06e384;
$blue-color: #0692e3;

/* line-heights */
$line-heading: 1;
$line-medium: 1.25;
$line-normal: 1.5;

$shadow-button: 0 2px 5px rgba(0, 0, 0, 0.2);
$shadow-normal: 0 5px 10px rgba(0, 0, 0, 0.2);
$shadow-big: 0 5px 30px rgba(0, 0, 0, 0.3);

$hide-sidebar-treshhold: 900px;
