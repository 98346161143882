@import "../node_modules/@fortawesome/fontawesome-pro/css/all.min.css";
@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "assets/scss/angular-material";
@import "~@ng-select/ng-select/themes/material.theme.css";
@import "assets/scss/common";
@import "assets/scss/vars";
@import "assets/scss/fonts";
@import "assets/scss/article";

html, body {
    width: 100%;
    height: 100%;
    user-select: none;
    background: none;
}

@page {
    size: A4 portrait;
}

.mat-tab-label-active {
    opacity: 1 !important;
}


.container {
    @media screen and (max-width: 1024px) {
        max-width: 100%;
    }
}

.topbar {
    position: absolute;
    display: flex;
    top: 0;
    left: 3.5rem;
    right: 0;
    padding: 1.625rem 1rem 1.625rem 0;
    justify-content: space-between;
    flex-direction: row;
    @media screen and (min-width: 375px) {
        left: 4rem;
        padding: 1.625rem;
    }
    @media screen and (min-width: 576px) {
        left: 6rem;
        padding: 2rem;
        flex-direction: row;
    }

    .title {
        h1 {
            margin: 0;
            text-transform: uppercase;
        }
    }
}

*:focus {
    outline: 0;
}

.cdk-overlay-pane {
    user-select: none;
}

.cdk-overlay-dark-backdrop {
    background-color: rgba(0, 0, 0, 0.7);
}

.pushpin-changes-dialog, .part-dialog, .confirm-dialog, .pushpin-dialog, .pushpinpart-log-dialog, .price-log-dialog, .excel-export-dialog {
    max-width: 90vw !important;

    .mat-dialog-container {
        position: relative !important;
        padding: 0;
        width: 500px;

        height: auto;
        max-height: 500px;

        .dialog-container {
            display: flex;
            flex-direction: column;
            height: auto;
            max-height: 500px;
            padding: 1.5rem;

            .dialog-close {
                position: absolute;
                top: 1rem;
                right: 1rem;
            }

            .dialog-title {
                display: flex;
                flex: 0 0 auto;
                font-size: 1.5rem;
                margin-bottom: 1.5rem
            }

            .dialog-content {
                word-break: break-word;
                display: flex;
                flex: 1 1 auto;
                position: relative;
                overflow-y: auto;

                table {
                    margin-bottom: 0;
                }

                p {
                    margin-bottom: 2rem;
                }
            }

            @media screen and (min-width: 576px) {
                padding: 2rem;
                .dialog-close {
                    top: 1.5rem;
                    right: 1.5rem;
                }
            }
        }
    }

    &.pushpin-changes-dialog {
        .mat-dialog-container {
            width: 950px;
            max-height: 90vh;

            .dialog-container {
                max-height: 90vh;
            }
        }
    }

    &.pushpin-dialog {
        .mat-dialog-container {
            width: 600px;
            max-height: 90vh;

            .dialog-container {
                max-height: 90vh;
            }
        }
    }
}

.filter-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: black;
    opacity: 0.7;
    z-index: 2;
    @media screen and (min-width: $hide-sidebar-treshhold) {
        display: none;
    }
}

.crow-dialog {

    .mat-dialog-content {
        max-height: 83vh !important;
    }

    max-width: 100vw !important;
    min-height: 393px;
    max-height: 96vw !important;
    @media screen and (max-width: 500px) {
        height: 100%;
        max-height: 100% !important;
    }
}

.image-viewer-dialog, .image-viewer {
    max-width: 100vw !important;
    max-height: 100vh !important;
    width: 100%;
    @media screen and (max-width: 500px) {
        max-height: 100% !important;
        max-width: 100vw !important;
    }

    .mat-dialog-container {
        padding: 0;
        box-shadow: none;
        background-color: transparent;
        overflow: visible;
    }
}

.filterholder {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    width: 100%;
}

.login-background {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    position: relative;
    background-image: url('assets/images/asphalt.jpg');
    background-size: cover;
    background-position: center;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    overflow: hidden;

    &:before {
        content: url('assets/logos/VV.svg');
        position: absolute;
        z-index: 0;
        opacity: 0.2;
        left: 33.333%;
        bottom: 0;
        height: 100vh;
        display: block;
        @media screen and (min-width: 576px) {
            left: 50%;
            height: auto;
            width: 75vw;
        }
    }

    .title {
        h1 {
            margin-bottom: 0;
        }

        p {
            margin-top: 1rem;
            margin-bottom: 0;
        }

        margin-bottom: 2rem;
    }


    .login-form, .login-block {
        flex: 0 0 100%;
        flex-direction: column;
        display: flex;
        position: relative;
        max-width: 30rem;
        padding: 1.5rem;
        background: $white-color;
        border-radius: 0.25rem;
        box-shadow: $shadow-normal;
        text-align: center;
        margin-top: 42px;

        form {
            flex: 0 0 100%;
            display: flex;
            flex-direction: column;
        }

        @media screen and (min-width: 576px) {
            padding: 3rem;
            text-align: left;
        }

        .login-buttons {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            @media screen and (min-width: 576px) {
                flex-direction: row-reverse;
            }

            .mat-raised-button {
                font-size: 1rem;
                margin-bottom: 0.5rem;
                @media screen and (min-width: 576px) {
                    margin-bottom: initial;
                }

                .mat-button-wrapper {
                    text-transform: uppercase;
                    font-family: 'Roboto Condensed', sans-serif;
                }
            }
        }
    }
}

// IE 11 Fix....
_:-ms-fullscreen, :root .ie11up {
    .login-background {
        .login-form, .login-block {
            display: block;
        }
    }
}

.controls-top, .controls-bottom {
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    z-index: 1;
    position: absolute;

    &:not(.left) {
        right: 0;
    }

    align-items: center;

    &.disable {
        display: none;
        @media screen and (min-width: $hide-sidebar-treshhold) {
            display: flex;
        }
    }

    .fab {
        flex-grow: 0;
    }
}

.controls-top {
    &.left {
        left: 0;

        &:not(.opened) {
            padding-left: 75px;
        }

        margin-left: 2rem;

        &.opened {
            margin-left: 21rem;
        }
    }

    top: 0;
    padding-top: 2rem;
    margin-right: 2rem;

    .fab {
        margin-bottom: 0.5rem;

        i.area-icon {
            text-align: center;
            display: block;
            height: 1.25rem;
            width: 1.25rem;
            background: url('./assets/icons/black/area.svg');
            background-size: cover;
        }
    }
}

.controls-bottom {
    bottom: 0;
    padding-bottom: 1.5rem;
    margin-right: 1.5rem;

    .fab {
        margin-top: 0.5rem;
    }
}

.bold {
    font-weight: bold;
}

.log-date-split {
    padding: 15px 0 3px 0;
}

.map-container {
    .filterholder {
        app-pushpin-selection {
            &.visible {
                margin-right: -19rem;
            }
        }
    }
}

.mat-tab-label {
    min-width: 80px !important;
}

mat-form-field.txtblack {
    .mat-input-element:disabled,
    .mat-form-field-type-mat-native-select.mat-form-field-disabled
    .mat-form-field-infix::after,
    label.mat-form-field-label {
        color: rgba(0, 0, 0, 0.87) !important;
    }
}

.mat-snack-bar-container {
    background: rgba(255, 255, 255, 0.8);
    border: 1px solid #e30613;
    color: #000;
    padding: 7px !important;
    min-height: auto !important;

    .mat-simple-snackbar {
        color: #000;
        font-weight: normal;

        > span {
            text-align: center;
            display: block;
            width: 100%;
        }

        > .mat-simple-snackbar-action {
            color: #000;
        }
    }
}

.topbar {
    .controls {
        div {
            margin-right: 5px;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

.address-list {
    mat-option {
        line-height: 30px;
        height: 30px;
        font-size: 13px;
        padding: 0 16px;
    }
}

.txtwhite {
    color: #FFF;
}


.version-info {
    text-align: right;
    font-size: 12px;
}

.ng-dropdown-panel {
    width: auto !important;
    max-width: 550px;
    min-width: 100%;
}
