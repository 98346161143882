ng-select.ng-invalid.ng-touched .ng-select-container:after {
    border-color: #DC3545;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-box-shadow: none;
}

.spacer {
    flex: 1 1 auto;
}

.ucfirst {
    display: inline-block;

    &:first-letter {
        text-transform: uppercase;
    }
}

.curpoint {
    cursor: pointer;
}

body {
    margin: 0;
}

.container {
    padding-left: 24px;
    padding-right: 24px;
}

.button {
    padding: 0.75rem 1rem;
    border-radius: 0.25rem;
}

.fab {
    background-color: $white-color;
    box-shadow: $shadow-button;
    display: inline-block;
    cursor: pointer;
    transition: .5s;

    &#btn-Save, &#btn-AddImage {
        padding: 0.875rem;

        i {
            font-size: 1.5rem;
            width: 1.5rem;

        }
    }

    i {
        color: $black-color;
        transition: .5s;
        width: 1.25rem;
        font-size: 1.25rem;
        text-align: center;

        &.fa-sign-out-alt {
            transform: rotate(180deg);
        }
    }

    .mat-badge-content {
        top: 50% !important;
        left: -.875rem !important;
        transform: translateY(-50%) !important;
    }

    &.small {
        padding: 0.625rem;
        border-radius: 1.25rem;
    }

    &.big {
        padding: 1.125rem;
        border-radius: 1.75rem;
    }

    &.active {
        background-color: $black-color;

        i {
            color: $white-color;
        }
    }

    &.primary {
        background-color: $red-color;

        i {
            color: $white-color;
        }
    }

    &.secondary {
        background-color: $yellow_color;

        i {
            color: $white-color;
        }
    }

    &.disabled {
        pointer-events: none;
        background-color: #CCCCCC;

        i {
            color: $white-color;
        }
    }

    &.disabled-events {
        background-color: #CCCCCC;

        i {
            color: $white-color;
        }
    }

    &:focus, &:active {
        transform: scale(1.025)
    }
}

.controls-bottom {
    label {
        margin-bottom: 0px;

        > .fab {
            &.big {
                padding: 1rem;
            }
        }
    }
}

.textlink {
    font-family: 'Roboto Condensed', sans-serif;
    text-transform: uppercase;
    color: $red-color;

    &.back {
        &:before {
            content: '';
            background-image: url('../icons/red/angle.svg');
            background-size: cover;
            height: 1.5rem;
            width: 1.5rem;
            position: relative;
            top: 0.375rem;
            display: inline-block;
            transform: rotate(180deg);
            margin-right: 0.25rem;
        }
    }

    &.next {
        &:after {
            content: '';
            background-image: url('../icons/red/angle.svg');
            background-size: cover;
            height: 1.5rem;
            width: 1.5rem;
            position: relative;
            top: 0.375rem;
            display: inline-block;
            margin-left: 0.25rem;
        }
    }
}

.text-red {
    color: $red-color;
}

a {
    color: $red-color;

    &:hover {
        color: darken($red-color, 10);
    }
}
