@import "vars";
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:700|Roboto:300,400,400i,500,700,700i');

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
    color: $black_color;
    line-height: $line-heading;
    font-weight: bold;
}
html, body {
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
p, .p {
    font-size: 1rem;
    line-height: $line-normal;
}
h1, .h1 {
    font-size: 2.5rem;
}
h2, .h2 {
    font-size: 2rem;
}
h3, .h3 {
    font-size: 1.75rem;
}
h4, .h4 {
    font-size: 1.5rem;
}
h5, .h5 {
    font-size: 1.25rem;
}
h6, .h6 {
    font-size: 1rem;
}
.label {
    font-size: 0.875rem;
}
.condensed {
    font-family: 'Roboto Condensed', sans-serif;
    text-transform: uppercase;
}
.white-text {
    color: $white-color
}